/* eslint-disable vars-on-top */
var debounce = require('lodash/debounce');

var DISABLED_BTN_CLASS = 'disabled-btn';
var HIDE_CLASS = 'd-none';
var SHOW_CLASS = 'd-block';
var ADD_CART_BUTTON_CLASS = 'button.add-to-cart, button.add-to-cart-global, .btn-update-wishlist-product, .update-cart-product-global';

function updateQuantityButtonsAndError($productContainer, hasErrors, $addToCartContainer, $globalAddToCart) {
  var $quantityInput = $productContainer.find('.quantity-input, .quantity-input-widget');
  var quantity = parseInt($quantityInput.val(), 10);
  var maxQuantity = parseInt($quantityInput.data('max'), 10);

  var $increaseButton = $productContainer.find('.quantity-increase, .quantity-widget-increase');
  var $decreaseButton = $productContainer.find('.quantity-decrease, .quantity-widget-decrease');
  var $errorContainer = $productContainer.find('.quantity-error');
  var $warningContainer = $productContainer.find('.quantity-warning');


  if (quantity <= 1) {
    $decreaseButton.addClass(DISABLED_BTN_CLASS).attr('disabled', true);
  } else {
    $decreaseButton.removeClass(DISABLED_BTN_CLASS).attr('disabled', false);
  }

  if (quantity === maxQuantity) {
    $increaseButton.addClass(DISABLED_BTN_CLASS).attr('disabled', true);
    $errorContainer.removeClass(SHOW_CLASS).addClass(HIDE_CLASS);
    $warningContainer.removeClass(HIDE_CLASS).addClass(SHOW_CLASS);
  }

  if (quantity > maxQuantity) {
    $increaseButton.addClass(DISABLED_BTN_CLASS).attr('disabled', true);
    $errorContainer.removeClass(HIDE_CLASS).addClass(SHOW_CLASS);
    $warningContainer.removeClass(SHOW_CLASS).addClass(HIDE_CLASS);
    if ($addToCartContainer) {
      $addToCartContainer.find(ADD_CART_BUTTON_CLASS).attr('disabled', true);
    } else {
      $(ADD_CART_BUTTON_CLASS).attr('disabled', true);
    }
  } else if (quantity < maxQuantity) {
    $increaseButton.removeClass(DISABLED_BTN_CLASS).attr('disabled', false);
    $errorContainer.removeClass(SHOW_CLASS).addClass(HIDE_CLASS);
    $warningContainer.removeClass(SHOW_CLASS).addClass(HIDE_CLASS);
  }

  if ($addToCartContainer) {
    var errorExists = hasErrors();
    if (errorExists) {
      $addToCartContainer.find(ADD_CART_BUTTON_CLASS).attr('disabled', true);
      if ($globalAddToCart) { $globalAddToCart.addClass('disabled'); }
    } else {
      $addToCartContainer.find(ADD_CART_BUTTON_CLASS).attr('disabled', false);
      if ($globalAddToCart) { $globalAddToCart.removeClass('disabled'); }
    }
  }

  if (!$addToCartContainer) {
    var errorExist = hasErrors();
    if (errorExist) {
      $(ADD_CART_BUTTON_CLASS).attr('disabled', true);
      if ($globalAddToCart) { $globalAddToCart.addClass('disabled'); }
    } else {
      $(ADD_CART_BUTTON_CLASS).attr('disabled', false);
      if ($globalAddToCart) { $globalAddToCart.removeClass('disabled'); }
    }
  }
}

function getDebouncedFn(fn) {
  return debounce(function (url, $productContainer) {
    fn(url, $productContainer);
  }, 300);
}

function getCurrentQuantity(context) {
  var $quantityInput = $(context);
  return parseInt($quantityInput.val(), 10);
}

function getMaxQuantity(context) {
  var $quantityInput = $(context);
  return parseInt($quantityInput.data('max'), 10);
}

function getStep(context) {
  var $quantityInput = $(context);
  return parseInt($quantityInput.data('step'), 10);
}

function setQuantiy(context, value) {
  var $quantityInput = $(context);
  $quantityInput.val(value);
}

function preventInvalidInput(e) {
  if (e.key === ',' || e.key === '.') {
    e.preventDefault();
  }
}

function resetOnBlur($this) {
  if ($this.val() === '' || $this.val() < 1) {
    $this.val(1);
  }
}

module.exports = {
  updateQuantityButtonsAndError: updateQuantityButtonsAndError,
  getDebouncedFn: getDebouncedFn,
  getCurrentQuantity: getCurrentQuantity,
  getMaxQuantity: getMaxQuantity,
  getStep: getStep,
  setQuantiy: setQuantiy,
  preventInvalidInput: preventInvalidInput,
  resetOnBlur: resetOnBlur
};
