'use strict';

var cart = require('../cart/cart');

var updateMiniCart = true;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
  // $.spinner().stop();
  var status = data.success ? 'alert-success' : 'alert-danger';

  if ($('.add-to-wishlist-messages').length === 0) {
    $('body').append('<div class="add-to-wishlist-messages "></div>');
  }
  $('.add-to-wishlist-messages')
    .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

  setTimeout(function () {
    $('.add-to-wishlist-messages').remove();
  }, 1500);
  var $targetElement = $('a[data-pid="' + data.pid + '"]').closest('.product-info').find('.remove-product');
  var itemToMove = {
    actionUrl: $targetElement.data('action'),
    productID: $targetElement.data('pid'),
    productName: $targetElement.data('name'),
    uuid: $targetElement.data('uuid')
  };
    // $('body').trigger('afterRemoveFromCart', itemToMove);
    // setTimeout(function () {
    //     $('.cart.cart-page #removeProductModal').modal();
    // }, 2000);
}

module.exports = function () {
  cart();

  // moveToWishlist funtion from WL plugin move items from Cart to wishlist returns json object with success or error message
  $('body').on('click', '.product-move .move', function (e) {
    e.preventDefault();
    var url = $(this).attr('href');
    var pid = $(this).data('pid');
    var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
    var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
    optionId = optionId || null;
    optionVal = optionVal || null;
    if (!url || !pid) {
      return;
    }

    // $.spinner().start();
    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: {
        pid: pid,
        optionId: optionId,
        optionVal: optionVal
      },
      success: function (data) {
        displayMessageAndRemoveFromCart(data);
      },
      error: function (err) {
        displayMessageAndRemoveFromCart(err);
      }
    });
  });

  $('.minicart').on('count:update', function (event, count) {
    if (count && $.isNumeric(count.quantityTotal)) {
      $('.minicart .minicart-quantity').text(count.quantityTotal);
      $('.minicart .minicart-link').attr({
        'aria-label': count.minicartCountOfItems,
        title: count.minicartCountOfItems
      });
    }
  });

  $('.minicart').on('mouseenter touchstart', function () {
    if ($('.search:visible').length === 0) {
      return;
    }
    var url = $('.minicart').data('action-url');
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    if (count !== 0 && $('.minicart .popover.show').length === 0) {
      if (!updateMiniCart) {
        $('.minicart .popover').addClass('show');
        return;
      }

      $('.minicart .popover').addClass('show');
      // $('.minicart .popover').spinner().start();
      $.get(url, function (data) {
        $('.minicart .popover').empty();
        $('.minicart .popover').append(data);
        updateMiniCart = false;
        // $.spinner().stop();
      });
    }
  });
  $('body').on('touchstart click', function (e) {
    if ($('.minicart').has(e.target).length <= 0) {
      $('.minicart .popover').removeClass('show');
    }
  });
  $('.minicart').on('mouseleave focusout', function (event) {
    if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
      event.stopPropagation();
      return;
    }
    $('.minicart .popover').removeClass('show');
  });
  $('body').on('change', '.minicart .quantity', function () {
    if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
      location.reload();
    }
  });
  $('body').on('product:afterAddToCart', function () {
    updateMiniCart = true;
  });
  $('body').on('cart:update', function () {
    updateMiniCart = true;
    var url = $('.mirakl-shipping-price').data('mirakl-update-url');
    $.ajax({
      url: url,
      method: 'get',
      dataType: 'json',
      success: function (data) {
        if (!data.hasSH02response && data.isMiraklOrder) {
          if (!$('.mirakl-shipping-price').hasClass('d-none')) {
            $('.mirakl-shipping-price').addClass('d-none');
            $('.mirakl-shipping-message').text(
              data.miraklEmptyShippingPriceMessage
            );
            $('.mirakl-shipping-message').removeClass('d-none');
          }
          // eslint-disable-next-line eqeqeq
        } else if (data.basketModel.totals.miraklShippingPriceFormatted != null) {
          $('.mirakl-shipping-price-formatted').text(
            data.basketModel.totals.miraklShippingPriceFormatted
          );
          $('.mirakl-shipping-price').removeClass('d-none');
          $('.mirakl-shipping-message').addClass('d-none');
        } else if (data.isMiraklOrder !== true) {
          if (!$('.mirakl-shipping-price').hasClass('d-none')) {
            $('.mirakl-shipping-price').addClass('d-none');
          }
          if (!$('.mirakl-shipping-message').hasClass('d-none')) {
            $('.mirakl-shipping-message').addClass('d-none');
          }
        }
        $('.tax-total').text(data.basketModel.totals.totalTax);
        $('.grand-total').text(data.basketModel.totals.grandTotal);
      }
    });
  });
  $('body').on('shown.bs.modal', '#cart-modal', function () {
    $('body').addClass('modal-open');
  });
  $('body').on('hidden.bs.modal', '#cart-modal', function () {
    $('body').removeClass('modal-open');
  });
  $(document).on('click', '#cart-modal', function () {
    $('body').addClass('modal-open');
  });
};
